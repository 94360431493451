"use client";
import React, { useEffect, useMemo, useState } from "react";
import type { AppProps } from "next/app";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import Head from "next/head";
import nProgress from "nprogress";
import { combineComponents } from "utils/combineComponents";
import { ReauthorizationContextProivder } from "context/ReauthorizeContext";
import dynamic from "next/dynamic";
// Contexts
import { AppContextProvider } from "context/AppContext";
import { AppealContextProvider } from "context/AppealContext";
import { AuthContext, AuthContextProivder } from "context/AuthContext";
import { PortfolioSignupContextProvider } from "context/PortfolioSignupContext";
import { ReferralContextProvider } from "context/ReferralContext";
import { ResponsiveContextProvider } from "context/ResponsiveContext";
import { OfferContextProvider } from "context/OfferContext";
import { SpeedInsights } from "@vercel/speed-insights/next";
import statePages from "config/state-pages.json";

// Components
import Layout from "layout";
import OfferModal from "elements/OfferModal";
import Script from "next/script";
import { onOwnwellDotCom } from "utils/onOwnwellDotCom";

import { ErrorBoundary } from "elements/ErrorBoundary";
import StorageRequired from "components/pages/Error/StorageRequired";
import StagingEnvLogin from "components/pages/StagingEnv/Login";
import { isStagingEnv } from "utils/isStagingEnv";
import { AbTest } from "elements/AbTest";
import { WIM_TRACKING_CLICK_ID_KEY } from "utils/constants";
import { UTMParamsContextProvider } from "context/UTMParamsContext";

const StagingEnvToolkit = dynamic<{}>(
	isStagingEnv()
		? () =>
				import("components/pages/StagingEnv/Toolkit").then(
					m => m.StagingEnvToolkit
				)
		: async () => () => null,
	{
		ssr: false,
	}
);

// ORDER MATTERS (Later contexts can use previous ones);
const AllContexts = combineComponents(
	AppContextProvider,
	AuthContextProivder,
	AppealContextProvider,
	PortfolioSignupContextProvider,
	ResponsiveContextProvider,
	ReferralContextProvider,
	OfferContextProvider,
	ReauthorizationContextProivder,
	UTMParamsContextProvider
);

const DEFAULT_LOCALE = "en-US";

const App = ({
	children,
	locale,
}: {
	children: React.ReactNode;
	locale: string;
}) => {
	const [hasError, setHasError] = useState(false);
	const [storageAvailable, setStorageAvailable] = useState<boolean>();
	const [localizeLoaded, setLocalizeLoaded] = useState(false);

	useEffect(() => {
		try {
			window.localStorage.setItem("lsa", "1"); // lsa = local storage available
			if (window.localStorage.getItem("lsa") === "1") {
				setStorageAvailable(true);
			} else {
				setStorageAvailable(false);
			}
		} catch {
			setStorageAvailable(false);
		}
	}, []);

	useEffect(() => {
		// Load hubspot chatbot after App has mounted
		// if (process.env.NODE_ENV !== "development") {
		//   loadHubspotChatbot();
		// }
	}, []);

	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		const handler = () => {
			if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
				//@ts-ignore
				if (window.ndp) {
					//@ts-ignore
					window.ndp("track", "PAGE_VIEW");
				}

				//@ts-ignore
				if (window.fbq) {
					//@ts-ignore
					window.fbq("track", "PageView");
				}

				//@ts-ignore
				if (window.HubSpotConversations && window.HubSpotConversations.widget) {
					//@ts-ignore
					window.HubSpotConversations.widget.refresh();
				}
			}
		};

		handler();

		return () => {
			handler();
		};
	}, [pathname, searchParams]);

	useEffect(() => {
		try {
			if (
				searchParams.get("Clickid_Subid") &&
				typeof searchParams.get("Clickid_Subid") === "string"
			) {
				window.localStorage.setItem(
					WIM_TRACKING_CLICK_ID_KEY,
					searchParams.get("Clickid_Subid") as string
				);
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		if (localizeLoaded) {
			// @ts-ignore
			Localize.setLanguage(`${locale}`);
		}
	}, [localizeLoaded, locale]);

	if (storageAvailable === false) {
		return (
			<Layout>
				<StorageRequired />
			</Layout>
		);
	}

	return (
		<ErrorBoundary setHasError={setHasError}>
			<AllContexts>
				<AuthContext.Consumer>
					{({ isAuthenticatedToStaging }) => {
						if (isStagingEnv() && isAuthenticatedToStaging === false) {
							return <StagingEnvLogin />;
						} else {
							return (
								<>
									{children}
									<OfferModal />
								</>
							);
						}
					}}
				</AuthContext.Consumer>
				{process.env.NODE_ENV === "production" && onOwnwellDotCom() && (
					<>
						<Script
							type="text/javascript"
							strategy="lazyOnload"
							id="nextdoor-pixel"
							dangerouslySetInnerHTML={{
								__html: `
									(function(win, doc, sdk_url){
									if(win.ndp) return;
									var tr=win.ndp=function(){
									tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
									};
									tr.queue = [];
									var s='script';
									var new_script_section=doc.createElement(s);
									new_script_section.async=!0;
									new_script_section.src=sdk_url;
									var insert_pos=doc.getElementsByTagName(s)[0];
									insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
									})(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');

									ndp('init','3f363a8a-d339-46a8-a509-35bbc456bad0', {})
									ndp('track','PAGE_VIEW');
           						 `,
							}}></Script>
						<noscript>
							<img
								height="1"
								width="1"
								style={{ display: "none" }}
								src="https://flask.nextdoor.com/pixel?pid=3f363a8a-d339-46a8-a509-35bbc456bad0&ev=PAGE_VIEW&noscript=1"
							/>
						</noscript>
						<Script
							type="text/javascript"
							strategy="lazyOnload"
							id="facebook-pixel"
							dangerouslySetInnerHTML={{
								__html: `
									!function(f,b,e,v,n,t,s)
									{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
									n.callMethod.apply(n,arguments):n.queue.push(arguments)};
									if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
									n.queue=[];t=b.createElement(e);t.async=!0;
									t.src=v;s=b.getElementsByTagName(e)[0];
									s.parentNode.insertBefore(t,s)}(window, document,'script',
									'https://connect.facebook.net/en_US/fbevents.js');
									fbq('init', '839933773237503');
									fbq('track', 'PageView');
                				`,
							}}></Script>
						<noscript>
							<img
								height="1"
								width="1"
								style={{ display: "none" }}
								src="https://www.facebook.com/tr?id=839933773237503&ev=PageView&noscript=1"
							/>
						</noscript>
						<Script
							type="text/javascript"
							strategy="lazyOnload"
							id="linkedin-pixel"
							dangerouslySetInnerHTML={{
								__html: `_linkedin_partner_id = "6098692"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
								(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`,
							}}
						/>
						<noscript>
							<img
								height="1"
								width="1"
								style={{ display: "none" }}
								alt=""
								src="https://px.ads.linkedin.com/collect/?pid=6098692&fmt=gif"
							/>
						</noscript>
						<Script id="google-tag-manager" strategy="afterInteractive">
							{`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-5R9VPQT');
                            `}
						</Script>
						<noscript>
							<iframe
								src="https://www.googletagmanager.com/ns.html?id=GTM-5R9VPQT"
								height="0"
								width="0"
								style={{ display: "none", visibility: "hidden" }}></iframe>
						</noscript>
						<Script
							src="https://www.googletagmanager.com/gtag/js?id=G-56GYJF25YS"
							strategy="afterInteractive"
						/>
						<Script id="google-analytics" strategy="afterInteractive">
							{`
                              window.dataLayer = window.dataLayer || [];
                              function gtag(){window.dataLayer.push(arguments);}
                              gtag('js', new Date());

                              gtag('config', 'G-56GYJF25YS');
                              gtag('config', 'AW-622658771');
                            `}
						</Script>
						{/* Bing Conversion Tracking */}
						<Script id="bing-pixel" strategy="afterInteractive">
							{`
                              (function(w,d,t,r,u)
                              {
                                var f,n,i;
                                w[u]=w[u]||[],f=function()
                                {
                                  var o={ti:"187035092"};
                                  o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
                                },
                                n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
                                {
                                  var s=this.readyState;
                                  s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
                                },
                                i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
                              })
                              (window,document,"script","//bat.bing.com/bat.js","uetq");
                        `}
						</Script>
						{/* Twitter */}
						<img
							style={{
								display: "none",
							}} /* display none prevents layout side effects */
							src={`https://p.xad.com?id=258528&sid=7469793&ts=${Date.now()}`}
						/>
						<img
							style={{
								display: "none",
							}} /* display none prevents layout side effects */
							src={`https://bidagent.xad.com/conv/258532?ts=${Date.now()}`}
						/>
						<Script id="cj-page-visit" strategy="afterInteractive">
							{`

                            if (!window.cj) window.cj = {}; 
  
                            cj.sitePage = { 
                                enterpriseId: 1571499, 
                                pageType: 'homepage', 
                                userId: '', 
                                emailHash: '', 
                                referringChannel: '<referringChannel>',
                                cartSubtotal: 0,
                                items: [] 
                            }; 
                        `}
						</Script>
						<Script id="cj-page-visit-2" strategy="afterInteractive">
							{`
                            (function(a,b,c,d){
                                a='https://www.mczbf.com/tags/711865311811/tag.js'; 
                                b=document;c='script';d=b.createElement(c);d.src=a; 
                                d.type='text/java'+c;d.async=true; 
                                d.id='cjapitag'; 
                                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a) 
                            })();
                            `}
						</Script>
						<Script id="tik-tok-pixel" strategy="afterInteractive">
							{`
                            !function (w, d, t) {
                              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                              ttq.load('CN5S6MBC77U0MBPFPLUG');
                              ttq.page();
                            }(window, document, 'ttq');
                        `}
						</Script>
						<Script id="tatari-pixel" strategy="afterInteractive">
							{`
                                !function(){try{!function(t,i){if(!i.version){window.tatari=i,i.init=function(t,n){var e=function(t,n){i[n]=function(){t.push([n].concat(Array.prototype.slice.call(arguments,0)))}};"track pageview identify".split(" ").forEach(function(t){e(i,t)}),i._i=t,i.config=n,i.pageview()},i.version="1.2.1";var n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://d2hrivdxn8ekm8.cloudfront.net/tag-manager/721b290f-89a7-45f7-a68f-481b7bf5f38c-latest.js";var e=t.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)}}(document,window.tatari||[])}catch(t){console.log(t)}}(); tatari.init('721b290f-89a7-45f7-a68f-481b7bf5f38c')
                            `}
						</Script>
						<Script id="podscribe-pixel" strategy="afterInteractive">
							{`
                            (function (w, d) {
                                var id = 'podscribe-capture',
                                n = 'script';
                                var e = d.createElement(n);
                                e.id = id;
                                e.async = true;
                                e.src = 'https://d34r8q7sht0t9k.cloudfront.net/tag.js';
                                var s = d.getElementsByTagName(n)[0];
                                s.parentNode.insertBefore(e, s);
                                e.addEventListener('load', function() {
                                  w.podscribe('init', { user_id: 'd8656980-7862-4277-ac8d-6a5f81a2dc01', advertiser: 'ownwell' });
                                  w.podscribe('view');
                                })
                              })(window, document);
                            `}
						</Script>
						<Script id="reddit-pixel" strategy="afterInteractive">
							{`
                                !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_ewm8ojqc8dk9', {});rdt('track', 'PageVisit');
                            `}
						</Script>
						<img
							src="https://adresults-58-adswizz.attribution.adswizz.com/fire?pixelId=5c876d96-9012-4c14-8fcf-b7604775991d&type=sitevisit&subtype=PageVisit1&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-"
							style={{ display: "none" }}
						/>
						<Script
							type="text/javascript"
							src="https://www.innovation-24-details.com/js/800114.js"
							strategy="afterInteractive"
						/>
						<noscript>
							<img
								alt=""
								src="https://www.innovation-24-details.com/800114.png"
								style={{ display: "none" }}
							/>
						</noscript>
						<Script id="twitter-tracking" strategy="afterInteractive">
							{`
							!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
							},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
							a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
							twq('config','p35zc');
							`}
						</Script>
						<Script id="linear-pixel" strategy="afterInteractive">
							{`
								var urlVisit = document.location.href;
								var ttdimgpixel = document.createElement('img');
								ttdimgpixel.height = '0';
								ttdimgpixel.width = '0';
								ttdimgpixel.style.display = 'none';
								ttdimgpixel.src = 'https://insight.adsrvr.org/track/pxl/?adv=lse7296&ct=0:dam1iex&fmt=3&td1=PageVisit1&td2='+urlVisit;
								ttdimgpixel.async = 'true';
								document.body.appendChild(ttdimgpixel);
							`}
						</Script>
					</>
				)}
				{isStagingEnv() && <StagingEnvToolkit />}
				<Script
					id="localize"
					src="https://global.localizecdn.com/localize.js"
					onLoad={() => {
						// @ts-ignore
						// prettier-ignore
						!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);

						// @ts-ignore
						Localize.initialize({
							key: "LNzuA0NDFaw3g",
							rememberLanguage: true,
							blockedClasses: ["no-translate"],
						});

						setLocalizeLoaded(true);
					}}
				/>
			</AllContexts>
			<SpeedInsights sampleRate={0.3} />
		</ErrorBoundary>
	);
};

export default App;
